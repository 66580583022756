import logo from './images/logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div>
          <img src={logo} alt="logo"></img>
        </div>
        <div>
          <ul>
            <li>About Me</li>
            <li>Projects</li>
            <li>Resume</li>


          </ul>
        </div>
      </header>
    </div>
  );
}

export default App;
